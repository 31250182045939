b,
strong {
  font-weight: $weight-bold;
}

a {
  color: $link-color;
  transition: $transition-base;

  &:hover,
  &:focus,
  &:active {
    //color: darken($primary-color, 5%);
    color: $link-hover-color;
    //text-decoration: none;
  }
}

h1,
h2,
h3 {
  font-family: $family-header;
  font-weight: $weight-normal;
  color: $header-color;
  line-height: 1.2;
  margin: 0.5em 0 0.3em;
}

h4 {
  //color: $color-dark-gray;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 1px;
}

h5 {
  //color: $color-dark-gray;
  font-weight: 400;
  line-height: 1.2;
}

//new
h1{
  line-height: 1;
  font-weight: $weight-normal;
  color: $font-color;
  font-family: $family-header;
  font-size: 2.2rem;
  strong, b{
    font-weight: $weight-semi-bold;
    color: $primary-color;
  }
}

h2{
  line-height: 1;
  margin: 0.3em 0;
  font-family: $family-header;
  font-weight: $weight-light;
  color: $font-color;
  strong, b{
    font-weight: $weight-semi-bold;
  }
}

h3{
  font-family: $family-header;
  font-weight: $weight-light;
  color: $color-gray;
  strong, b{
    font-weight: $weight-semi-bold;
  }
}

@include media-breakpoint-up(lg) {

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    //font-size: 1.2rem;
    font-size: 1.4rem;
  }

  h5 {
    font-size: 0.8rem;
  }

}

// html editor
.text-tiny {
  font-size: 0.75rem;
}

.text-big {
  font-size: 1.5rem;
}