$top-bar-z-index: 1000 !default;

.topNav {
  position: fixed;
  //background-color: rgba($color: $color-black, $alpha: 0.7);
  background-color: transparent;
  z-index: $top-bar-z-index;
  height: map-get($top-bar-height, mobile);
  top: 0;
  width: 100%;
  font-size: 0.75rem;
  @include media-breakpoint-up(lg) {
    height: map-get($top-bar-height, desktop);
  }

  .topNav__logo{
    img {
      transition: all 0.2s ease-in-out;
      width: 220px;
      @include media-breakpoint-up(lg) {
        width: 320px;
      }
    } 
  }

  &.scrolled{
    //background-color: rgba($color: #fff, $alpha: 0.9);
    background-color: rgba($color: $color-black, $alpha: 0.7);
    .topNav__logo{
      img {
        width: 150px;
        @include media-breakpoint-up(lg) {
          width: 200px;
        }
      }
    }
  }

}

a.topNav__back {
  position: absolute;
  left: 1rem;
  line-height: map-get($top-bar-height, mobile);
  color: white;
  &:hover {
    color: white;
    text-decoration: none;
  }
  @include media-breakpoint-up(lg) {
    line-height: map-get($top-bar-height, desktop);
  }
}

.topNav__sep {
  display: inline-block;
  padding: 0 1rem;
  color: white;
}