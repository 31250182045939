@import '../modules//buttons/skewButton.scss';

.contentPanel {
  //background: white;
  padding: 1rem 0;
  position: relative;
  word-break: break-word;

  >* {
    z-index: 1;
    position: relative;
  }

  .button {
    //@extend %skewButton;
    display: inline-block;
    font-family: $family-header;
    //background: $primary-color;
    background-image: linear-gradient(to right, #77cf22, #429b57);
    transition: all .2s ease-in-out;
    padding: 0.5rem 1.2rem;
    line-height: 1;
    color: white;
    transition: all 0.3s;
    border-radius: 5px;
    span {
      text-align: center;
      display: inline-block;
      width: 100%;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      //background: $link-hover-color;
      background-image: linear-gradient(to right, $link-hover-color, $link-hover-color);
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.commonFooter:before,
  &.bottomPanel:before {
    display: none;
  }
}

.contentPanel--dark {
  background: linear-gradient(rgba(#000, 1), rgba(#000, 0.7));
  color: white;
}

.contentPanel__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &:before {
    content: '';
    background: rgba(#000, 0.05);
    width: 100%;
    height: 100%;
    left: 65%;
    top: 0;
    transform: skewX(-12deg) translateX(-100%);
    pointer-events: none;
    position: absolute;
    z-index: 0;
  }

  .contentPanel--dark &:before {
    background: rgba(#fff, 0.05);
  }
}