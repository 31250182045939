// overriding bootstrap variables in _boostrap-override.scss

$family-base: "proxima-soft", sans-serif;
$family-header: "zen-new", sans-serif;

$weight-light: 300;
$weight-normal: 400;
$weight-semi-bold: 600;
$weight-bold: 600;

// colors - sister colours from bootrap
$color-blue:    #007bff;
$color-red:     #fe3a43;
$color-orange:  #fd7e14;
$color-green : #77cf22;
$color-dark-green : #429b57;
$color-black: #10170a;
$color-gray: #777777;
$color-gray2: #333;
$color-light-gray: #e4e4e4;

// more colors
$color-gold:  #faa222;
$color-dark-gray: #252525;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-green;
$font-color: $color-light-gray;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color : #fff;

$link-color: $primary-color;
$link-hover-color: $color-dark-green;

// top-bar
$top-bar-border: (mobile: 5px, desktop: 12px);
$top-bar-height: (mobile: 44px, desktop: 56px);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;