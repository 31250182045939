.devMediaQueryStatus {
  position: fixed;
  bottom: 100px;
  left: 0;
  z-index: 10000;

  &__current {
    padding: 0.3rem 1rem;
    font-size: 0.75rem;
    background-color: black;
    color: white;

    &:before {
      content: 'xs';

      @include media-breakpoint-up(sm) {
        /*>=576*/
        content: 'sm';
      }

      @include media-breakpoint-up(md) {
        /*>=768*/
        content: 'md';
      }

      @include media-breakpoint-up(lg) {
        /*>=992*/
        content: 'lg';
      }

      @include media-breakpoint-up(xl) {
        /*>=1200*/
        content: 'xl';
      }

      @include media-breakpoint-up(xxl) {
        /*>=1600*/
        content: 'xxl';
      }
    }
  }
}