@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~react-day-picker/lib/style.css';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
@import './layout/hamburger';
@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
//@import './modules/sliderEmbla';
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/youtube';
@import './modules/dev';

@import './site/index';


.container{
    @include media-breakpoint-up(xl){
        max-width: 1140px;
    }
}

.btn-primary{
    color: $font-color-white !important;
    border: none !important;
    background-image: linear-gradient(to right, #77cf22, #429b57);
    transition: all .2s ease-in-out;
    &:hover{
        //background-color: $link-hover-color !important;
        background-image: linear-gradient(to right, $link-hover-color, $link-hover-color);
    }
}