.banner-item {
  max-width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  // &.mask::before{
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 1;
  //   background : rgba(0,0,0,.3);
  // }
}

.banner_item__text, .banner-item__link {
  font-family: $family-header;
  color: #fff;
  font-size: 1.6rem; 
  
  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }
}

.banner_item__text {
  //background: rgba(#000, 0.5);
  background-image: linear-gradient(to right, rgba($color: #77cf22, $alpha: 0.9), rgba($color: #429b57, $alpha: 0.9));
  text-align: left;
  //padding: 0.5rem 0 0.7rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
  //padding-top: 5px;
  //padding-bottom: 5px;

  .banner_item__text__warpper{
     //border: 1px solid red;
     max-width: 960px;
     margin: auto;

    .banner-item__title{
      display: inline-block;
      padding: 0px 2.5rem;
      //background-color: $primary-color;
      line-height: 1.2;
      font-weight: $weight-light;
      padding-bottom: 4px;
      border-radius: 10px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.banner-item__link {
  &:hover, &:focus {
    color: #fff;
  }
}