.searchBar {
  //margin-top: 70px;
  padding: 15px;
  background-color: $primary-color;
  position: relative;
}

.searchBar__input {
  @include placeholder-color(#fff);
  //background-color: #999;
  background-color: transparent;
  width: 100%;
  height: 2.2rem;
  line-height: 2.2rem;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  border: 2px solid #ffffff;
  //border-radius: 0.25rem;
  border-radius: 20px;
  padding-left: 3rem;
  &:before{
    content: "adfksjlk";
  }
}

.searchBar__searchIcon{
  position: absolute;
  width: 35px;
  top: 28%;
  left: 7%;
}