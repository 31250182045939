@import 'standard';
@import 'thin';
@import 'heavy';

.bannerSize, .bannerSize--standard {
  @extend %bannerSize--standard;
}

.bannerSize--heavy {
  @extend %bannerSize--heavy;
}

.bannerWrapperSize--thin .bannerSize, .bannerSize--thin {
  @extend %bannerSize--thin;
}