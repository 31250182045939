.invalid {
  font-size: 85%;
  color: red;
}

// overriding bootrap invalid text box (remove x icon)
.was-validated .form-control:invalid, .form-control.is-invalid {
  background:none;
}

@import 'steps';