.main-content {
  //padding-top: map-get($top-bar-height, mobile);
  @include media-breakpoint-up(lg) {
    //padding-top: map-get($top-bar-height, desktop);
  }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  button {
    //margin-right: 0.7rem;
    font-weight: $weight-bold;
  }
}
