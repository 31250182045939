@import "../bannerSize/full.scss";
.youtubePlayer {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(xl) {
    // 1200px and up
    height: calc(100vh - 2rem);
  }

  >* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.youtubePlayer__iframe {
  z-index: 1;
  max-width: none;
  transition: opacity 1.5s;
  opacity: 0;
}

.youtubePlayer__poster {
  @include bg-img();
  z-index: 2;
  transition: opacity 1.5s;
  opacity: 1;
}

.youtubePlayer__mask {
  //background-color: rgba(#000, 0.2);
  z-index: 3;
}

.youtubePlayer--loaded {
  .youtubePlayer__iframe {
    opacity: 1;
  }

  .youtubePlayer__poster {
    opacity: 0;
  }
}

.youtubePlayer--full {
  @extend %bannerSize--full;
  
}