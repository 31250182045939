.pageTiles {
  @include tile--titleDesc($ratio: 80%);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pageTile__content {

  &:hover,
  &:focus {
    text-decoration: none;

    .pageTile_details:before {
      background: $primary-color;
    }

    .pageTile__title,
    .pageTile_desc {
      color: white;
    }
  }
}

.pageTile__title {
  font-family: $family-header;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  //margin: 0.5rem 0 0px;
  margin: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
}

.pageTile__hr{
  border-top: 2px solid white;
  margin: 0px 30%;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 0.9rem;
  color: $font-color;
}

.pageTile_details {
  //position: relative;
  position: absolute;
  overflow: hidden;
  flex-grow: 1;
  //padding: 0.5rem 3rem 0.8rem 1rem;
  padding: 0px;
  text-align: center;
  width: 100%;
  bottom: 0px;
  background-color: rgba($color: $color-black, $alpha: 0.5)
  // &:before {
  //   content: '';
  //   background-image: linear-gradient(to right, #f4f4f4, #e0e0e0);
  //   transition: all 0.9s;
  //   width: 100%;
  //   height: 100%;
  //   transform: skewX(-12deg) translateX(0);
  //   transform-origin: top left;
  //   pointer-events: none;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: -1;
  // }
}


.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(to bottom left, rgba(#fff, 0.3), rgba(#fff, 0));
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}
