.productViewPanel {
  text-align: left;

  .container {
    max-width: 1200px;
    margin: auto;
  }

  .right {
    display: flex;
    flex-direction: column;
  }
}

.productView {
  margin-bottom: 3rem;
}

.productView__title {
  line-height: 1;
  margin: 0 0 0.5rem;
}

.productView__price {
  font-size: 1.6rem;
  font-weight: bold;
}

.productView__productCode {}

.productView__intro {
  white-space: pre-line;
  margin: 1.2rem 0;
}


.productView__images {
  // display: flex;
  // flex-wrap: wrap;

  padding: 10px 40px;
  margin-bottom: 1rem;

  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
    background: #ccc;
    padding: 9px 13px;
    border-radius: 50%;

    &:before {
      display: inline-block;
      width: 8px;
      font-size: 8px;
    }
  }

  .slick-prev {
    left: -40px;

    &:before {
      content: url('/assets/icons/chevron-left-solid.svg');
      transform: translate(-2px, 0);
    }
  }

  .slick-next {
    right: -40px;

    &:before {
      content: url('/assets/icons/chevron-right-solid.svg');
    }
  }
}

.productView__image {
  // flex: 0 1 33.33%;
  padding: 15px;

  img {
    cursor: pointer;
  }
}


.productView__packages {
  display: flex;
  flex-wrap: wrap;
  //margin-top: auto;
  margin-bottom: 1rem;
  //justify-content: center;
}

.productView__package {
  flex: 0 1 auto;
  max-width: 300px;
}

.productView__linkItem {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.productView__linkItem--page{
  background-color: #fff;
  color: $font-color;
    border: 1px solid $font-color;
    border-radius: 0;
    font-size: 0.8rem;
    letter-spacing: 1px;
    transition: all .15s;
    padding: 0.5rem 1rem;
    text-align: center;
    display: inline-block;
    &:hover, &:focus {
      text-decoration: none;
      background-color: $font-color;
      color: white;
    }
}

@import './productView__tabs';
@import './imagePopup';